@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.products-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h3 {
  color: #bb86fc;
  text-align: center;
  margin-bottom: 30px;
}

.products-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.product-item {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-item h4 {
  color: #bb86fc;
  margin-bottom: 10px;
  text-align: center;
}

.product-item img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin: 10px 0;
}

.product-item button {
  background-color: #03dac6;
  color: #000000;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: auto;
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #3700b3;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
}

.pagination button:hover:not(:disabled) {
  background-color: #bb86fc;
}

.pagination button:disabled {
  background-color: #1e1e1e;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
}

.total-products {
  text-align: center;
  margin-top: 20px;
  color: #03dac6;
}

/* Responsive for Mobile */
@media (max-width: 600px) {
  .products-list {
    grid-template-columns: 1fr;
  }
}












/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.products-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  
}

h3 {
  color: #bb86fc;
  text-align: center;
  margin-bottom: 30px;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 8px;
  margin-bottom: 20px;
}

.error {
  color: #cf6679;
}

.products-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.product-item {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.product-item:hover {
  background-color: #2c2c2c;
}

.product-item h4 {
  color: #bb86fc;
  margin-bottom: 10px;
  text-align: center;
}

.product-item p {
  margin: 5px 0;
  font-size: 0.9em;
  text-align: center;
}

.product-item img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin: 10px 0;
}

.product-item button {
  background-color: #03dac6;
  color: #000000;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
  font-weight: bold;
  align-items: center;
}

.product-item button:hover {
  background-color: #018786;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #3700b3;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.pagination button:hover:not(:disabled) {
  background-color: #bb86fc;
}

.pagination button:disabled {
  background-color: #1e1e1e;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
}

.total-products {
  text-align: center;
  margin-top: 20px;
  color: #03dac6;
}

@media (max-width: 600px) {
  .products-list {
    grid-template-columns: 1fr;
  }
} */










/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.products-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h3 {
  color: #bb86fc;
  text-align: center;
  margin-bottom: 30px;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 8px;
  margin-bottom: 20px;
}

.error {
  color: #cf6679;
}

.products-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.product-item {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.product-item:hover {
  background-color: #2c2c2c;
}

.product-item h4 {
  color: #bb86fc;
  margin-bottom: 10px;
}

.product-item p {
  margin: 5px 0;
  font-size: 0.9em;
  text-align: center;

}

.product-item img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #3700b3;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.pagination button:hover:not(:disabled) {
  background-color: #bb86fc;
}

.pagination button:disabled {
  background-color: #1e1e1e;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
}

.total-products {
  text-align: center;
  margin-top: 20px;
  color: #03dac6;
}

@media (max-width: 600px) {
  .products-list {
    grid-template-columns: 1fr;
  }
} */