.cart-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  background-color: #1a1a1a;
  color: #e0e0e0;
}

.cart-container h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
}

.cart-table-container {
  background-color: #2c2c2c;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.cart-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.cart-table th,
.cart-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #3a3a3a;
}

.cart-table th {
  background-color: #1c1c1c;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
}

.cart-table tr:last-child td {
  border-bottom: none;
}

.quantity-btn, .delete-btn {
  border: none;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.quantity-btn {
  background-color: #2743fe;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 5px;
}

.delete-btn {
  background-color: #ef4444;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
}

.quantity-btn:hover {
  background-color: #3182ce;
}

.delete-btn:hover {
  background-color: #c53030;
}

.quantity-btn:active, .delete-btn:active {
  transform: scale(0.95);
}

.cart-total {
  margin-top: 20px;
  text-align: right;
  font-size: 16px;
  color: #ffffff;
}

.cart-total p {
  margin: 10px 0;
}

.cart-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.button-continue-shopping,
.button-checkout {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.button-continue-shopping {
  background-color: #4a5568;
  color: #ffffff;
  margin-right: 15px;
}

.button-checkout {
  background-color: #38a150;
  color: #ffffff;
}

.button-continue-shopping:hover {
  background-color: #718096;
}

.button-checkout:hover {
  background-color: #26b92b;
}

.button-continue-shopping:active,
.button-checkout:active {
  transform: scale(0.98);
}

.shipping-address {
  margin-top: 40px;
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
}

.shipping-address h4 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #ffffff;
}

.shipping-address form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.shipping-address label {
  display: flex;
  flex-direction: column;
  color: #e0e0e0;
}

.shipping-address input {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  font-size: 16px;
  background-color: #3a3a3a;
  color: #ffffff;
  transition: border-color 0.3s;
}

.shipping-address input:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 0.2rem rgba(66, 153, 225, 0.25);
}

@media (max-width: 768px) {
  .cart-container {
    padding: 20px 10px;
  }

  .cart-table th,
  .cart-table td {
    padding: 10px;
  }

  .quantity-btn,
  .delete-btn {
    padding: 5px 8px;
    font-size: 12px;
  }

  .cart-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .button-continue-shopping,
  .button-checkout {
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .cart-container h3 {
    font-size: 20px;
  }

  .cart-table th,
  .cart-table td {
    padding: 8px;
    font-size: 14px;
  }

  .quantity-btn,
  .delete-btn {
    padding: 4px 6px;
    font-size: 12px;
  }

  .cart-total {
    font-size: 16px;
  }

  .button-continue-shopping,
  .button-checkout {
    font-size: 14px;
    padding: 10px 20px;
  }

  .shipping-address form {
    grid-template-columns: 1fr;
  }
}