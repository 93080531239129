.footer {
    display: flex;
    justify-content: center;
    background-color: #1a1a1a;
    color: #e0e0e0;
    padding: 40px 20px;
    flex-wrap: wrap;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
  }
  
  .footer-column {
    flex: 1;
    padding: 0 20px;
    min-width: 250px;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  
  .footer-column h3 {
    margin-bottom: 20px;
    color: #ffffff;
    font-size: 1.2rem;
  }
  
  .footer-column p {
    margin-bottom: 15px;
    line-height: 1.6;
    font-size: 0.9rem;
  }
  
  .social-media {
    list-style: none;
    padding: 0;
  }
  
  .social-media li {
    margin-bottom: 15px;
  }
  
  .social-media li a {
    color: #b3b3b3;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-media li a:hover {
    color: #ffffff;
    text-decoration: none;
  }
  
  /* Mobile view */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-column {
      text-align: center;
      padding: 0;
      width: 100%;
    }
  
    .footer-column:last-child {
      margin-bottom: 0;
    }
  
    .footer-column h3 {
      font-size: 1.1rem;
    }
  
    .footer-column p {
      font-size: 0.85rem;
    }
  
    .social-media {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  
    .social-media li {
      margin: 0 10px 10px;
    }
  }