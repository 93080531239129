/* Home.css */

.unique-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .unique-diwali-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  /* Desktop styles */
  @media screen and (min-width: 768px) {
    .unique-image-container {
      padding: 20px 10px; /* Reduced horizontal padding */
    }
  
    .unique-diwali-image {
      max-width: 98%; /* Increased from 95% */
      max-height: 95vh;
    }
  }
  
  /* Mobile styles */
  @media screen and (max-width: 767px) {
    .unique-image-container {
      height: auto;
      padding: 10px;
    }
  
    .unique-diwali-image {
      width: 100%;
      height: auto;
    }
  }