@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.eco-header {
  /* background-color: #4caf50; */
  background: linear-gradient(
    135deg,
    #092e09 0%,
    #1a711a 25%,
    #7bd07e 50%,
    #59d05d 75%,
    #1d591d 100%
  );
  font-family: 'Poppins', sans-serif;
  padding: 2.5rem 0;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.logo {
  display: flex;
  align-items: center;
}

.leaf-icon {
  width: 70px;
  height: 70px;
  margin-right: 10px;
  border-radius: 50%;
}

.logo h1 {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 600;
}

.nav-menu {
  display: flex;
  gap: 1.5rem;
}

.nav-menu a {
  color: #b8e0b8;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  transition: color 0.3s ease;
}

.nav-menu a:hover {
  color: #ffffff;
}

.cta-button {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    padding: 1rem;
  }

  .logo {
    margin-bottom: 1rem 1rem;
  }

  .nav-menu {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .nav-menu a {
    padding: 0.5rem 0;
  }

  .cta-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .logo h1 {
    font-size: 1.5rem;
  }

  .nav-menu a {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }
}