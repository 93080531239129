.payment-container {
    display: flex;
    background-color: #262626;
    color: #ffffff;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    max-width: 1000px;
    margin: 2rem auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .payment-info {
    flex: 1;
    padding-right: 2rem;
  }
  
  .payment-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #4CAF50;
    border-bottom: 2px solid #4CAF50;
    padding-bottom: 0.5rem;
  }
  
  .info-section {
    margin-bottom: 2rem;
  }
  
  .info-section h2 {
    font-size: 1.5rem;
    color: #4CAF50;
  }
  
  .info-section p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .info-section p strong {
    color: #81C784;
  }
  
  .qr-code-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 300px;
  }
  
  .qr-code {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .gpay-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .paid-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
    width: 100%;
    max-width: 200px;
  }
  
  .paid-button:hover {
    background-color: #45a049;
  }
  
  .paid-button:active {
    transform: scale(0.98);
  }
  
  @media (max-width: 768px) {
    .payment-container {
      flex-direction: column;
      padding: 1.5rem;
    }
  
    .payment-info {
      padding-right: 0;
      margin-bottom: 2rem;
    }
  
    .qr-code-section {
      flex: 0 0 auto;
      width: 100%;
    }
  
    .payment-title {
      font-size: 1.75rem;
    }
  
    .info-section h2 {
      font-size: 1.25rem;
    }
  
    .info-section p {
      font-size: 0.9rem;
    }
  
    .paid-button {
      padding: 12px 20px;
      font-size: 1.1rem;
    }
  }













/* .payment-container {
    display: flex;
    background-color: #262626;
    color: #ffffff;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    max-width: 1000px;
    margin: 2rem auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .payment-info {
    flex: 1;
    padding-right: 2rem;
  }
  
  .payment-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #4CAF50;
    border-bottom: 2px solid #4CAF50;
    padding-bottom: 0.5rem;
  }
  
  .info-section {
    margin-bottom: 2rem;
  }
  
  .info-section h2 {
    font-size: 1.5rem;
    color: #4CAF50;
  }
  
  .info-section p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .info-section p strong {
    color: #81C784;
  }
  
  .qr-code-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 300px;
  }
  
  .qr-code {
    margin-bottom: 1rem;
  }
  
  .gpay-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .paid-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
    width: 100%;
    max-width: 200px;
  }
  
  .paid-button:hover {
    background-color: #45a049;
  }
  
  .paid-button:active {
    transform: scale(0.98);
  }
  
  @media (max-width: 768px) {
    .payment-container {
      flex-direction: column;
      padding: 1.5rem;
    }
  
    .payment-info {
      padding-right: 0;
      margin-bottom: 2rem;
    }
  
    .qr-code-section {
      flex: 0 0 auto;
      width: 100%;
    }
  
    .payment-title {
      font-size: 1.75rem;
    }
  
    .info-section h2 {
      font-size: 1.25rem;
    }
  
    .info-section p {
      font-size: 0.9rem;
    }
  
    .paid-button {
      padding: 12px 20px;
      font-size: 1.1rem;
    }
  }





 */
